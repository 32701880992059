import React from "react";
import styled from "styled-components";
import { FlexCenter } from "../style/index";
import { Link } from "gatsby";
import LogoImage from "../images/bridge5asialogo.png";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
const MenuList = [
  {
    item: "首页",
    type: "home",
    router: "/",
  },
  {
    item: "关于我们",
    type: "about",
    router: "/about/",
  },
  {
    item: "大数据可行性",
    type: "bigData",
    router: "/bigdata/",
  },
  {
    item: "数字化资管",
    type: "bi",
    router: "/bi/",
  },
  {
    item: "资产战略",
    type: "strategy",
    router: "/strategy/",
  },
  {
    item: "研究报告",
    type: "report",
    router: "/report/",
  },
  {
    item: "联系我们",
    type: "contact",
    router: "/contact/",
  },
];

export const NavBar = ({ main, style }) => {
  const { t, i18n } = useTranslation();
  const { changeLanguage } = useI18next();
  return (
    <Wrap style={style}>
      <Nav>
        <Logo src={LogoImage} alt="" />
        <Ul>
          {MenuList.map((menu, i) => (
            <Li key={i}>
              <Link
                to={i18n.language === "en" ? `/en${menu.router}` : menu.router}
                style={{ color: main ? "#2f3852" : "#fff" }}
                activeStyle={{ color: "#f1404b" }}
              >
                {t(menu.type)}
              </Link>
            </Li>
          ))}
        </Ul>
        <Switcher>
          <div onClick={() => changeLanguage("zh")} className={`box ${i18n.language === "zh" ? "active" : ""}`}>
            中文
          </div>
          <div onClick={() => changeLanguage("en")} className={`box ${i18n.language === "en" ? "active" : ""}`}>
            EN
          </div>
        </Switcher>
      </Nav>
    </Wrap>
  );
};

const Wrap = styled(FlexCenter)`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 125px;
  padding-top: 43px;
  z-index: 1000;
`;

const Logo = styled.img`
  width: 114px;
`;

const Nav = styled.nav`
  min-width: 1130px;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Ul = styled.ul`
  display: flex;
  justify-content: center;
`;

const Li = styled.li`
  font-size: 21px;
  font-weight: 500;
  padding: 0 22px;
  text-align: center;
  cursor: pointer;
  color: ${(props) => props.theme.mainColor};
  a {
    &:hover {
      color: #f1404b;
    }
  }
`;

const Switcher = styled.div`
  position: absolute;
  right: -100px;
  top: 50%;
  transform: translateY(-50%);
  width: 60px;
  display: flex;
  height: 20px;
  border: 1px solid #204b7a;
  .box {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #204b7a;
    cursor: pointer;
  }
  .active {
    background-color: #204b7a;
    color: #fff;
  }
`;
