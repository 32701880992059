import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import House from "../images/homepage/house.svg";
import Banner from "../images/homepage/banner2.png";
import EnBanner from "../images/enbanner.png";
import Quo from "../images/quo.png";
import AmssLogoImg from "../images/amsslogo2.svg";
import PlanImg from "../images/plan.png";
import EnPlanImg from "../images/en-plan.png";
import { graphql } from "gatsby";
import { NavBar } from "../components/nav-bar";
import { Footer } from "../components/Footer/index";
import Partner from "../components/Partner";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import BannerImg from "../images/img/banner.jpg";
import CircleImg from "../images/img/circle.png";

export default () => {
  const { t, i18n } = useTranslation();
  const { changeLanguage } = useI18next();
  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    pauseOnHover: false,
  };

  return (
    <Layout>
      <SliderWrap>
        <SliderNav>
          <NavBar main />
        </SliderNav>
        <Slider {...settings}>
          <FirstSection bg={BannerImg}>
            <FirstSectionWrap>
              {i18n.language === "zh" && (
                <MainBox>
                  <h1 className="title" style={{ fontWeight: "700" }} onClick={() => changeLanguage("en")}>
                    地产永远被资本驱动
                  </h1>
                  <p style={{ marginTop: "15px" }}>{t("AAM")}</p>
                  <p style={{ fontSize: "27px", fontWeight: "200" }}>{t("YRDS")}</p>
                </MainBox>
              )}
              {i18n.language === "en" && (
                <MainBox>
                  <h1 style={{ fontWeight: "700", fontSize: "38px", whiteSpace: "nowrap", fontFamily: "Impact" }} onClick={() => changeLanguage("en")}>
                    Real Estate is always
                  </h1>
                  <h1 style={{ fontStyle: "italic", fontWeight: "700", fontSize: "48px", whiteSpace: "nowrap" }} onClick={() => changeLanguage("en")}>
                    Capital Driven
                  </h1>
                  <p style={{ marginTop: "15px", fontSize: "28px" }}>{t("AAM")}</p>
                  <p style={{ fontSize: "27px", fontWeight: "200" }}>{t("YRDS")}</p>
                </MainBox>
              )}
              <CircleWrap>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Circle bg={CircleImg}>
                    <img src={AmssLogoImg} alt="" />
                    {t("IM")}
                  </Circle>
                  <Ul>
                    <Li>1. {t("FinancialModeling")}</Li>
                    <Li>2. {t("BF")}</Li>
                    <Li>3. {t("SP")}</Li>
                  </Ul>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Circle bg={CircleImg}>
                    <img src={AmssLogoImg} alt="" />
                    {t("AM")}
                  </Circle>
                  <Ul>
                    <Li>4. {t("RF")}</Li>
                    <Li>5. {t("BM")}</Li>
                    <Li>6. {t("RR")}</Li>
                  </Ul>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Circle bg={CircleImg}>
                    <img src={AmssLogoImg} alt="" />
                    {t("FM")}
                  </Circle>
                  <Ul>
                    <Li>7. {t("PA")}</Li>
                    <Li>8. {t("RM")}</Li>
                    <Li>9. {t("DI")}</Li>
                  </Ul>
                </div>
              </CircleWrap>
            </FirstSectionWrap>
          </FirstSection>
          <FirstSection bg={i18n.language === "en" ? EnBanner : Banner}>
            <FirstSectionWrap left>
              {i18n.language === "zh" ? (
                <MainBox2 main>
                  <h1 className="title" style={{ fontWeight: "700" }}>
                    地产金融当是科技驱动
                  </h1>
                  <p style={{ fontSize: "32px" }}>
                    <span className="strong">AMSS ⼤数据</span>驱动智能资管快车
                  </p>
                  <p style={{ fontSize: "32px", marginTop: "20px" }}>
                    <span style={{ fontWeight: "bold" }}>AMSS智能资产管理 </span>
                    <br />
                    <span style={{ fontWeight: 300 }}>– 地产金融数字化中枢</span>
                  </p>
                </MainBox2>
              ) : (
                <MainBox2 main>
                  <h1 className="eng-title" style={{ fontWeight: "700" }}>
                    Real Estate Finance
                    <br />
                    has never been more
                  </h1>
                  <h1 className="eng-title2" style={{ fontStyle: "italic", fontWeight: "700" }}>
                    Tech Driven
                  </h1>
                  <p style={{ fontSize: "32px", fontStyle: "italic" }}>Big Data + BI = AI</p>
                  <p
                    style={{
                      fontStyle: "italic",
                      textAlign: "center",
                      fontSize: "32px",
                      fontWeight: "700",
                      marginTop: "20px",
                      lightHeight: "1.2",
                    }}
                  >
                    AMSS – Smart Solution for
                    <br />
                    <strong style={{ fontWeight: "900", fontStyle: "italic" }}>Innovation Leaders</strong>
                  </p>
                </MainBox2>
              )}
            </FirstSectionWrap>
          </FirstSection>
        </Slider>
      </SliderWrap>
      <Main>
        <SecondSectionWrap>
          <VideoSection>
            <video width="90%" height="100%" controls disablePictureInPicture controlsList="nodownload">
              <source src="https://staticfiles-amssdata-com-cn.oss-cn-shanghai.aliyuncs.com/videos/amssdata-intro.mp4" type="video/mp4" />
            </video>
          </VideoSection>
          {i18n.language === "zh" ? (
            <VideoTitle>
              晤桥亚洲
              <br />
              以匠人精神与您携手迎向大数据文明
            </VideoTitle>
          ) : (
            <VideoTitleEn>
              AMSS defines a new era of <br /> Big Data solution
            </VideoTitleEn>
          )}
          <QuoImg src={Quo} />
          <HouseImg src={House} />
        </SecondSectionWrap>
      </Main>
      <ThirdSection>
        <PlanBox>
          <MainColorTitle style={{ marginBottom: "22px" }}>{t("AMSSSolutions")}</MainColorTitle>
          <img src={i18n.language === "en" ? EnPlanImg : PlanImg} width={1190} alt="plan" />
        </PlanBox>
      </ThirdSection>
      <Main>
        <FourthWrap>
          <CustomerWrap>
            <MainColorTitle>{t("AMSSClients")}</MainColorTitle>
            <Partner />
          </CustomerWrap>
        </FourthWrap>
      </Main>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["index"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Main = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const SliderWrap = styled.div`
  width: 100%;
  position: relative;
  .slick-slider {
    overflow: hidden !important;
  }
  .slick-dots {
    bottom: 40px !important;

    .slick-active {
      button:before {
        color: #d94250 !important;
        opacity: 1;
      }
    }
    button:before {
      color: #d8d8d8 !important;
      opacity: 1;
    }
  }
`;

const SliderNav = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1000;
`;
const Wrap = styled.div`
  min-width: 1130px;
  display: flex;
`;

const MainBox = styled.div`
  width: 406px;
  height: 311px;
  background-color: rgba(126, 1, 0, 0.7);
  line-height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 194px;
  color: ${(props) => (props.main ? props.theme.mainColor : "#fff")};
  .title {
    font-style: italic;
    font-size: 40px;
    font-weight: 500;
  }

  .strong {
    color: ${(props) => props.theme.red};
  }
  p {
    font-size: 32px;
  }
`;
const MainBox2 = styled.div`
  height: 311px;
  line-height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 194px;
  color: ${(props) => (props.main ? props.theme.mainColor : "#fff")};
  .title {
    font-style: italic;
    font-size: 40px;
    font-weight: 500;
  }
  .eng-title {
    font-size: 40px;
    font-weight: 500;
    line-height: 1.3;
  }
  .eng-title2 {
    font-size: 48px;
    font-weight: 500;
  }
  .strong {
    color: ${(props) => props.theme.red};
  }
  p {
    font-size: 32px;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: 500;
  letter-spacing: 1.19px;
  text-align: center;
  color: #fff;
`;

const MainColorTitle = styled(Title)`
  color: #2f3852;
`;

const SubTitle = styled.h6`
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0.7px;
  text-align: center;
  color: #fff;
`;

const FirstSection = styled(Main)`
  flex-direction: column;
  min-width: 1440px;
  background-image: ${(props) => `url(${props.bg})`};
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  padding-bottom: 150px;
  display: flex !important;
`;

const FirstSectionWrap = styled(Wrap)`
  max-width: 1130px;
  justify-content: ${(props) => (props.left ? "left" : "center")};
  display: flex !important;
`;

const CircleWrap = styled.div`
  display: flex;
  align-items: flex-end;
  margin-left: 70px;
  visibility: ${(props) => (props.close ? "hidden" : "visible")};
`;

const Circle = styled.div`
  margin-left: 23px;
  width: 193px;
  height: 185px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-image: ${(props) => `url(${props.bg})`};
  background-repeat: no-repeat;
  font-size: 26px;
  font-weight: bold;
  color: #2f3852;
  line-height: 1.5em;
  img {
    width: 50px;
  }
`;

const SecondSectionWrap = styled(Wrap)`
  padding: 35px 10px;
  position: relative;
`;

const HouseImg = styled.img`
  position: absolute;
  right: -60px;
  bottom: 40px;
  z-index: -1;
`;
const QuoImg = styled.img`
  position: absolute;
  right: 31%;
  top: 35px;
`;

const VideoSection = styled.div`
  width: 720px;
  height: 399px;
  background-color: #fff;
  video {
    padding: 0;
    width: 90%
    border: none !important;
  }
`;

const VideoTitleEn = styled.h1`
  font-size: 36px;
  font-family: Segoe UI, sans-serif;
  font-weight: 700;
  font-style: italic;
  line-height: 1.3;
  letter-spacing: 0.46px;
  margin-top: 30px;
  color: #2f3852;
  z-index: 1;
  text-align: center;
`;
const VideoTitle = styled.h1`
  font-size: 21px;
  font-weight: 500;
  line-height: 2.1;
  letter-spacing: 0.46px;
  margin-top: 26px;
  color: #2f3852;
  z-index: 1;
`;

const ThirdSection = styled(Main)`
  position: relative;
  background: ${(props) => props.theme.mainColor};
`;

const ThirdSectionWrap = styled(Wrap)`
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
`;

const TechWrap = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 115px;
  margin-top: 50px;
`;

const TechWrapNumber = styled.div`
  width: 301px;
  .main-title {
    font-size: 36px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.8px;
    color: #fff;
    margin-top: 15px;
  }
`;

const TechWrapBox = styled.div`
  flex: 1;
  .box-wrap {
    display: flex;
    justify-content: space-between;
  }
`;

const TechWrapBoxItem = styled.div`
  width: 240px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  background-color: #8697c8;
  visibility: ${(props) => props.hidden};
`;

const TechWrapBoxImage = styled.img`
  width: 70px;
`;
const TechWrapBoxTitle = styled.p`
  font-size: 21px;
  margin-top: 20px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.7px;
  text-align: center;
  color: #fff;
`;

const TechWrapDesc = styled.div`
  width: 100%;
  padding: 17px;
  margin-top: 17px;
  border-radius: 16px;
  border: solid 1px #979797;
  background-color: #dddfe6;
  font-size: 21px;
  line-height: normal;
  letter-spacing: 0.8px;
  .strong {
    color: ${(props) => props.theme.red};
    margin-left: 10px;
  }
`;

const FourthWrap = styled(Wrap)`
  flex-direction: column;
`;

const PlanBox = styled.div`
  width: 100%;
  padding: 35px 72px 70px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #e9eff2;
`;

const CustomerWrap = styled.div`
  margin-top: 88px;
`;

const Ul = styled.ul`
  margin-top: 20px;
  margin-left: 23px;
  white-space: nowrap;
`;
const Li = styled.li`
  font-size: 21px;
  width: 100%;
  color: #fff;
  line-height: 1.81em;
  text-shadow: #000 0px 3px 6px;
`;
